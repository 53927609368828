<template>
  <div>
    <pageTitle pageTitle="设置"></pageTitle>
    <div class="home--set--info">
      <vh-form
        :model="homeSetInfoForm"
        ref="homeSetInfoForm"
        :rules="homeSetInfoFormRules"
        label-width="94px"
      >
        <vh-form-item label="主页标题" prop="title">
          <vh-input
            placeholder="请输入账号昵称"
            v-model="homeSetInfoForm.title"
            autocomplete="off"
            :maxlength="30"
            class="input-length"
            show-word-limit
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="主页头像" prop="homepage_avatar">
          <upload
            class="upload__avatar"
            ref="account_avatar_uploader"
            :heightImg="120"
            :domain_url="domain_url"
            :saveData="saveImageData"
            :on-success="handleUploadAvatarSuccess"
            :on-change="handleUploadAvatarChange"
            @delete="deleteImgAvatar"
            :auto-upload="false"
          >
            <div slot="tip">
              <p>建议尺寸：140*140px，小于2M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </upload>
        </vh-form-item>
        <vh-form-item label="主页简介" prop="content">
          <!--<v-editor  :isReturn=true @returnChange="sendRewardData" ref="contentEditor"
                     v-model="homeSetInfoForm.content"
                     toolbar="fontsizeselect bold italic underline anchor | alignleft aligncenter alignright alignjustify | fullscreen">
          </v-editor>-->
          <vh-input
            type="textarea"
            :rows="5"
            v-model="homeSetInfoForm.content"
            :maxlength="150"
            autocomplete="off"
            show-word-limit
          ></vh-input>
        </vh-form-item>
        <vh-form-item label="背景图片" prop="img_url">
          <upload
            class="upload__bg__avatar"
            ref="account_bg_uploader"
            :heightImg="120"
            :domain_url="domain_bg_url"
            :saveData="saveImageData"
            :on-success="handleUploadSuccessBg"
            :on-change="handleUploadBgChange"
            @delete="deleteImgBg"
            :auto-upload="false"
          >
            <div slot="tip">
              <p>建议尺寸：1920*630px，小于4M</p>
              <p>支持jpg、gif、png、bmp</p>
            </div>
          </upload>
        </vh-form-item>
        <vh-form-item label="分享" class="switch-form-item">
          <div class="switch__box">
            <vh-switch
              v-model="homeSetInfoForm.show_share"
              :active-value="1"
              :inactive-value="0"
              active-color="#FB3A32"
              inactive-color="#CECECE"
              :active-text="
                homeSetInfoForm.show_share ? '已开启，主页显示分享功能' : '开启后，主页显示分享功能'
              "
            ></vh-switch>
          </div>
        </vh-form-item>
        <vh-form-item label="直播列表" class="switch-form-item">
          <div class="switch__box">
            <vh-switch
              v-model="homeSetInfoForm.show_webinar_list"
              :active-value="1"
              :inactive-value="0"
              active-color="#FB3A32"
              inactive-color="#CECECE"
              :active-text="
                homeSetInfoForm.show_webinar_list
                  ? '已开启，个人主页显示直播列表Tab页'
                  : '开启后，个人主页显示直播列表Tab页'
              "
            ></vh-switch>
          </div>
        </vh-form-item>
        <vh-form-item label="专题列表" v-if="vsQuanxian && vsQuanxian['subject_manager'] > 0">
          <div class="switch__box">
            <vh-switch
              v-model="homeSetInfoForm.show_subject"
              :active-value="1"
              :inactive-value="0"
              active-color="#FB3A32"
              inactive-color="#CECECE"
              :active-text="
                homeSetInfoForm.show_subject
                  ? '已开启，个人主页显示专题列表Tab页'
                  : '开启后，个人主页显示专题列表Tab页'
              "
            ></vh-switch>
          </div>
        </vh-form-item>
        <vh-form-item label="">
          <vh-button
            type="primary"
            class="length152"
            v-preventReClick
            round
            @click.prevent.stop="saveHandle"
          >
            保 存
          </vh-button>
          <vh-button round plain type="info" class="length152" @click.prevent.stop="returnHandle">
            取 消
          </vh-button>
        </vh-form-item>
      </vh-form>
    </div>
    <!-- 裁剪组件 -->
    <cropper
      ref="accountCropper"
      :currentMode="currentMode"
      @cropComplete="cropComplete"
      @resetUpload="resetChooseFile"
      :ratio="ratio"
    ></cropper>
  </div>
</template>

<script>
  import Upload from '@/components/Upload/main';
  import PageTitle from '@/components/PageTitle';
  import { sessionOrLocal, buildOssUploadProcessData, parseQueryString, buildUrlByMode } from '@/utils/utils';
  import cropper from '@/components/Cropper/index';
  export default {
    name: 'homeSetInfo.vue',
    components: {
      Upload,
      PageTitle,
      cropper
    },
    data() {
      return {
        homeSetInfoForm: {
          homepage_avatar: '',
          content: '',
          img_url: '',
          show_share: 1,
          show_webinar_list: 1, // 直播列表展示：0不展示 1展示
          show_subject: 1, // 专题展示：0不展示 1展示
          title: ''
        },
        reHomeSetInfo: {
          homepage_avatar: '',
          content: '',
          img_url: '',
          show_share: 1,
          show_webinar_list: 1, // 直播列表展示：0不展示 1展示
          show_subject: 1,
          title: ''
        },
        homeSetInfoFormRules: {
          title: [
            { required: true, message: '主页标题不能为空', trigger: 'blur' },
            { max: 30, message: '最多可输入30个字符', trigger: 'blur' },
            { min: 1, message: '请输入主页标题', trigger: 'blur' }
          ],
          content: [
            { required: false, message: '最多可输入150个字符', trigger: 'blur' },
            { max: 150, message: '最多可输入150个字符', trigger: 'blur' }
          ]
        },
        ratio: 1 / 1,
        isEdit: false,
        domain_url_mode: 1, // 主页头像显示模式
        domain_bg_url_mode: 1, // 背景图片显示模式
        upload_process_data: '',
        currentMode: 1
      };
    },
    computed: {
      domain_url() {
        if (!this.homeSetInfoForm.homepage_avatar) return '';
        return buildUrlByMode(this.homeSetInfoForm.homepage_avatar, this.domain_url_mode);
      },
      domain_bg_url() {
        if (!this.homeSetInfoForm.img_url) return '';
        return buildUrlByMode(this.homeSetInfoForm.img_url, this.domain_bg_url_mode);
      },
      // 保存的上传图片的附加数据
      saveImageData() {
        let imageData = {
          path: 'users/homepage-imgs',
          type: 'image'
        };
        if (this.upload_process_data) {
          imageData.process = this.upload_process_data;
        }
        return imageData;
      }
    },
    methods: {
      cropComplete(cropperData, url, mode, index) {
        
        if (cropperData) {
          this.upload_process_data = buildOssUploadProcessData({
            cropperData: cropperData
          });
        } else {
          this.upload_process_data = '';
        }

        this.$nextTick(() => {
          if (index == 1) {
            // 主页头像
            this.domain_url_mode = mode;
            this.$refs.account_avatar_uploader.upload();
          } else {
            // 背景图片
            this.domain_bg_url_mode = mode;
            this.$refs.account_bg_uploader.upload();
          }
        });
      },
      resetChooseFile(index) {
        if(index == 1) {
          this.$refs.account_avatar_uploader.resetChooseFile();
        } else {
          this.$refs.account_bg_uploader.resetChooseFile();
        }
      },
      deleteImgAvatar() {
        this.homeSetInfoForm.homepage_avatar = '';
      },
      deleteImgBg(){
        this.homeSetInfoForm.img_url = '';
      },
      handleUploadAvatarSuccess(res, file) {
        if (res.data) {
          this.homeSetInfoForm.homepage_avatar = res.data.domain_url;
        }
      },
      handleUploadSuccessBg(res, file) {
        if (res.data) {
          this.homeSetInfoForm.img_url = res.data.domain_url;
        }
      },
      /**
       * 图片本地裁剪预览
       * @param {*} file
       * @param {*} fileList
       */
      handleUploadAvatarChange(file, fileList) {
        console.log('handleUploadAvatarChange----->', file, fileList);
        this.ratio = 1 / 1;
        this.currentMode = this.domain_url_mode;
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, 1, 2, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.accountCropper.showModel(e.target.result, 1);
            };
          });
        }
      },
      
      handleUploadBgChange(file, fileList) {
        console.log('handleUploadChange----->', file, fileList);
        this.ratio = 192 / 63;
        this.currentMode = this.domain_url_bg_mode;
        if (file.status == 'ready') {
          this.$checkUploadType(file.raw, this, 1, 4, () => {
            let reader = new FileReader();
            reader.readAsDataURL(file.raw);
            reader.onload = e => {
              this.$refs.accountCropper.showModel(e.target.result, 2);
            };
          });
        }
      },
      sendRewardData(content) {
        console.log(content);
        if (content) {
          this.homeSetInfoForm.content = content;
        }
      },
      saveHandle() {
        this.$refs.homeSetInfoForm.validate(valid => {
          if (valid) {
            let params = {
              img_url: this.$parseURL(this.homeSetInfoForm.img_url).path,
              homepage_avatar: this.$parseURL(this.homeSetInfoForm.homepage_avatar).path,
              content: this.homeSetInfoForm.content,
              show_share: this.homeSetInfoForm.show_share, // 分享
              show_webinar_list: this.homeSetInfoForm.show_webinar_list, // 直播列表展示：0不展示 1展示
              show_subject: this.homeSetInfoForm.show_subject, // 专题展示：0不展示 1展示
              title: this.homeSetInfoForm.title
            };
            if (this.homeSetInfoForm.id) {
              params.id = this.homeSetInfoForm.id;
            }
            this.$fetch(this.homeSetInfoForm.id ? 'homeInfoEdit' : 'homeInfoCreate', params)
              .then(res => {
                console.log(res);
                this.setReportData();
                this.$vhMessage({
                  message: `保存基本设置成功`,
                  showClose: true,
                  // duration: 0,
                  type: 'success',
                  customClass: 'zdy-info-box'
                });
                // 回到前一个页面
                this.$router.push({
                  path: `/acc/myHome`
                });
                // window.location.reload();
              })
              .catch(res => {
                console.log(res);
                this.$vhMessage({
                  message: res.msg || '保存基本设置失败',
                  showClose: true,
                  // duration: 0,
                  type: 'error',
                  customClass: 'zdy-info-box'
                });
              });
          }
        });
      },
      setReportData() {
        if (this.domain_url !== this.reHomeSetInfo.homepage_avatar) {
          this.$vhall_paas_port({
            k: 100791,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        if (this.domain_bg_url !== this.reHomeSetInfo.img_url) {
          this.$vhall_paas_port({
            k: 100792,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        if (this.homeSetInfoForm.show_share !== this.reHomeSetInfo.show_share) {
          this.$vhall_paas_port({
            k: this.homeSetInfoForm.show_share ? 100793 : 100794,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        if (this.homeSetInfoForm.show_webinar_list !== this.reHomeSetInfo.show_webinar_list) {
          this.$vhall_paas_port({
            k: this.homeSetInfoForm.show_webinar_list ? 100795 : 100796,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
        if (this.homeSetInfoForm.show_subject !== this.reHomeSetInfo.show_subject) {
          this.$vhall_paas_port({
            k: this.homeSetInfoForm.show_subject ? 100797 : 100798,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: '',
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
        }
      },
      returnHandle() {
        this.$refs['homeSetInfoForm'].resetFields();
        this.isEditUnit = false;
        this.$router.push({
          path: `/acc/myHome`
        });
      },
      homeInfoGet() {
        this.$fetch('homeInfoGet', {
          home_user_id: this.$route.params.str
        })
          .then(res => {
            console.log(res);
            if (res && res.code === 200) {
              let { homepage_info } = res.data;
              if (homepage_info && homepage_info.img_url === '0') {
                homepage_info.img_url = '';
              }
      
              this.homeSetInfoForm = homepage_info || {
                show_share: 1, // 分享
                show_webinar_list: 1, // 直播列表展示：0不展示 1展示
                show_subject: 1 // 专题展示：0不展示 1展示
              };
              this.reHomeSetInfo = Object.assign({}, this.homeSetInfoForm);
              this.domain_url_mode = parseQueryString(this.homeSetInfoForm.homepage_avatar)['mode'] || 1;
              this.domain_bg_url_mode = parseQueryString(this.homeSetInfoForm.img_url)['mode'] || 1;
            }
          })
          .catch(err => {
            console.log(err);
          });
      },
      beforeRouteLeave(to, from, next) {}
    },
    created() {
      let vsPersonStr = sessionOrLocal.get('SAAS_VS_PES', 'localStorage');
      this.userId = JSON.parse(sessionOrLocal.get('userId'));
      if (vsPersonStr) {
        this.vsQuanxian = JSON.parse(vsPersonStr);
      }
      this.homeInfoGet();
    }
  };
</script>

<style lang="less" scoped>
  .home--set--info {
    .layout--right--main();
    .padding-form();
    .min-height();
    ::v-deep .vh-form {
      width: 800px;
    }
  }

  /* 图片上传 */
  // .upload__avatar {
  //   ::v-deep .vh-upload--picture-card {
  //     height: 180px;
  //   }
  //   ::v-deep .box > div {
  //     height: 180px;
  //   }
  // }
  // .upload__bg__avatar {
  //   ::v-deep .vh-upload--picture-card {
  //     height: 180px;
  //   }
  //   ::v-deep .box > div {
  //     height: 180px;
  //   }
  // }
</style>
